import React, { useState, useEffect } from 'react';
import { BsDatabaseFillAdd } from 'react-icons/bs';
import Todo from './Todo';
import { db } from './firebase';
import {
  query,
  collection,
  onSnapshot,
  updateDoc,
  doc,
  addDoc,
  deleteDoc,
  where, 
} from 'firebase/firestore';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';

const style = {
  container: 'max-w-screen-md mx-auto p-4 mt-40',
  heading: 'text-3xl font-bold text-center text-white p-2',
  form: 'flex justify-between',
  input: 'border p-2 w-full text-xl',
  button: 'border p-4 ml-2 bg-slate-500',
  count: 'text-center p-2',
};

function App() {
  const [todos, setTodos] = useState([]);
  const [input, setInput] = useState('');
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleSignup = async () => {
    const auth = getAuth();
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      setEmail('');
      setPassword('');
      setError('');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleLogin = async () => {
    const auth = getAuth();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      setEmail('');
      setPassword('');
      setError('');
    } catch (error) {
      setError(error.message);
    }
  };

  const handleLogout = async () => {
    const auth = getAuth();
    await signOut(auth);
  };

  const createTodo = async (e) => {
    e.preventDefault();
    if (input === '') {
      alert('Please enter a valid todo');
      return;
    }
    await addDoc(collection(db, 'todos'), {
      text: input,
      completed: false,
      userId: user.uid,
    });
    setInput('');
  };

  useEffect(() => {
    if (user) {
      const q = query(collection(db, 'todos'), where('userId', '==', user.uid));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let todosArr = [];
        querySnapshot.forEach((doc) => {
          todosArr.push({ ...doc.data(), id: doc.id });
        });
        setTodos(todosArr);
      });
      return () => unsubscribe();
    }
  }, [user]);

  const toggleComplete = async (todo) => {
    await updateDoc(doc(db, 'todos', todo.id), {
      completed: !todo.completed,
    });
  };

  const deleteTodo = async (id) => {
    await deleteDoc(doc(db, 'todos', id));
  };

  return (
    <div className={style.container}>
      {user ? (
        <div>
          <h2 className={style.heading}>Your To-Do List</h2>
          <button className='focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900'   onClick={handleLogout}>Logout</button>
          <div className={style.container}>
            <form onSubmit={createTodo} className={style.form}>
              <input
                value={input}
                onChange={(e) => setInput(e.target.value)}
                className={style.input}
                type="text"
                placeholder="Enter your Task"
              />
              <button className={style.button}>
                <BsDatabaseFillAdd size={30} />
              </button>
            </form>
            <ul>
              {todos.map((todo, index) => (
                <Todo
                  key={index}
                  todo={todo}
                  toggleComplete={toggleComplete}
                  deleteTodo={deleteTodo}
                />
              ))}
            </ul>
            {todos.length < 1 ? null : (
              <p className={style.count}>{`You have ${todos.length} todos`}</p>
            )}
          </div>
        </div>
      ) : (
        <div>
      <div>
  <h2 className={style.heading}>Login or Sign Up</h2>
      </div>
  <div className={`${style.container} shadow-lg p-4 rounded-lg bg-white`}>
    <div>
      <input
        className="w-full px-4 py-2 mb-3 leading-tight text-gray-700 transition duration-300 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        className="w-full px-4 py-2 mb-3 leading-tight text-gray-700 transition duration-300 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
    </div>
    <div>
      <button
        className="px-6 py-2 text-gray-100 transition duration-300 rounded bg-gradient-to-r from-gray-600 to-gray-400 hover:from-gray-400 hover:to-gray-600 hover:shadow-lg"
        onClick={handleLogin}
      >
        Login
      </button>
      <button
        className="px-6 py-2 text-purple-100 transition duration-300 rounded bg-gradient-to-r from-purple-600 to-purple-400 hover:from-purple-400 hover:to-purple-600 hover:shadow-lg"
        onClick={handleSignup}
      >
        Sign Up
      </button>
    </div>
    <p style={{ color: 'red' }}>{error}</p>
  </div>
</div>

      )}
    </div>
  );
}

export default App;
